<template>
<div class="ContactInformation d-flex justify-center align-start align-content-start flex-wrap">

	<!--TITULO-->
	<div class="TitleSection d-flex justify-start align-center">
		<h4>
			Contact Information
		</h4>
	</div>


	<!--CONTENIDO-->

	<div class="ContentDashboard d-flex justify-center align-center">
		<v-date-picker
      v-model="calendar"
      :events="events"
      @change="changeCalendar($event)"
      full-width
      no-title
      class="mt-4"
    ></v-date-picker>
	</div>
</div>
</template>
<script>
  export default {
    data: () => ({
      
      events: [ "2021-06-01", "2021-06-02" ],
    }),
    methods:{
    	changeCalendar(event){
    		alert(event)
    	}    
    }
    
  }
</script>
<style lang="less">
@import '~@/assets/style/Dashboard/General.less';
.ContentDashboard{
	height: 330px;
	overflow: hidden;
}

//BORDE Y TAMAÑO DE LOS INDICADORES DE FECHAS
.v-date-picker-table__events > div{
	border: solid 1px grey !important;
	width: 30px!important; height: 30px!important;
}
//COLOR DEL FONDO Y BORDE DE LOS  INDICADORES 
.v-application .warning{
	background-color: transparent !important;
	border-color:grey !important;
}
//POSISION DE LOS INDICADORES
.v-date-picker-table--date .v-date-picker-table__events {
	bottom: 23px !important;
	left: -1px !important;
}

//INDICADOR DE FECHA ACTUAL
.v-application .accent--text {
		font-weight: bolder !important;;
    color: black !important;
    caret-color: black !important;
}
</style>
<template>
<div class="AppMain d-flex flex-wrap"  >

  <!--MENU LATERAL IZQUIERDO-->
  <div class="LateralMenuContent HideOnMovil">
    <MenuGeneral></MenuGeneral>
  </div>

  <!--MENU PARA DISPOSITIVOS MOVILES-->
  <div class="SuperiorMenuContent ShowFlexOnMovil">
    <MenuGeneralMovil></MenuGeneralMovil>
  </div>


  <!--CONTENEDOR GENERAL-->
  <div class="GeneralContent FullOnMovil pl-10 pr-10">
  	<!--TITULO DE LA PÁGINA-->
  	<TitlePage title="Jane's Dashboard"></TitlePage>

    <!--INFORMACION DE CONTACTO-->
    <div class="GridDashboard d-flex justify-space-between align-start align-content-start flex-wrap">


    	<div class="columnLeft">
    		<ContactInformation></ContactInformation>
        <Contacts></Contacts>
    	</div>
    	<div class="columnRight">
    		<Calendar></Calendar>
        <WeeklyTasks></WeeklyTasks>
    	</div>

    </div>
	
  	

    <!--ESPACIO EN BLANCO-->
    <WhiteSpace></WhiteSpace>
  </div>




</div>
</template>
<script>
import TitlePage from '@/components/TitlePage.vue'
import MenuGeneral from '@/components/Menu/MenuGeneral.vue'
import MenuGeneralMovil from '@/components/Menu/MenuGeneralMovil.vue'
import ContactInformation from '@/components/Dashboard/ContactInformation.vue'
import Contacts from '@/components/Dashboard/Contacts.vue'
import Calendar from '@/components/Dashboard/Calendar.vue'
import WeeklyTasks from '@/components/Dashboard/WeeklyTasks.vue'


import WhiteSpace from '@/components/WhiteSpace.vue'

export default {
  name: 'Home',
  components: {
    TitlePage,
    MenuGeneral,
    MenuGeneralMovil,
    ContactInformation,
    Contacts,
    Calendar,
    WeeklyTasks,
    WhiteSpace
  },
  data(){
    return{
      ShowFilters:false
    }
  },
  methods:{
    //MOSTRAR EL MODAL DE FILTROS
    filtersAction(){
      this.ShowFilters = !this.ShowFilters;
    }
  }
}
</script>

<style lang="less" scoped>
.GridDashboard{
	width: 100%;
	height: auto;
	display: flex;

	.columnLeft{
		width: 38%;
		height: auto;
		margin-top: 15px;
	}

	.columnRight{
		width: 58%;
		height: auto;
		margin-top: 15px;
	}
}
</style>